import React from 'react';

// React
import { Disclosure } from '@headlessui/react'

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";

// Icons
import { ChevronDownIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline'


// Images
import HeaderImg from '../../images/features/work-queue/header.png';
import Calendar from '../../images/features/work-queue/calendar.jpg';
import Clock from '../../images/features/work-queue/clock.jpg';
import Audit from '../../images/features/work-queue/audit.jpg';
import WorkQueueExample from '../../images/home/WorkQueueExample.png';
import { Link } from 'gatsby';


const faqs = [
    {
        question: "How do I know if my central station is compatible with Tech Tester?",
        answer:
            `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
    },
    {
        question: "What do I need to setup an account with Tech Tester?",
        answer:
            "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
    },
    {
        question: "I use multiple central stations. Does Tech Tester allow that?",
        answer:
            "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
    },
    {
        question: "My free trial has expired, how do I continue to use Tech Tester?",
        answer:
            "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
    },
    {
        question: "When will my credit card be billed for Tech Tester?",
        answer:
            "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
    },
    {
        question: "I would like one of my technicians to have full access. Is that possible?",
        answer:
            "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function WorkQueue() {

    return (
        <div>
            <Seo title="Work Queue" description="Learn about the Tech Tester work queue and its features." />
            <Header/>
            {/* Hero section */}
            <div className="bg-myGray-300 py-12">
                <div className={"pt-10 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                    <div className={"my-auto text-left "}>
                        <h1 className={"md:text-4xl text-3xl text-myGray-600 font-bold"}>Work Queue</h1>
                        <h3 className={"text-myGray-600 text-lg pt-10"}>Manage your team more effectively and efficiently with Tech Tester.
                            Give your technicians access to the accounts that they need, when they need it. Nothing more.
                        </h3>
                        <Link to={'/contact'}>
                            <button  className="mt-10 inline-flex px-4 py-2 text-base border border-transparent rounded-md shadow-sm text-sm text-black font-bold bg-yellowTT hover:bg-yellowTT-alt"
                            >START NOW</button></Link>
                    </div>
                    <div className={"m-auto"}>
                        <img
                            className=""
                            src={WorkQueueExample}
                            alt="Temp Item"
                        />
                    </div>
                </div>
            </div>



            {/* Alternating Feature Sections */}
            <div className="relative overflow-hidden">
                <div aria-hidden="true" className="absolute z-10 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>

                <div className="relative z-20 pt-12">
                    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
                        <h2 className="pb-20 mt-16 text-3xl font-bold">Granular Team Management</h2>
                        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={Calendar} className={'h-52 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Date-Based Access
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    Tech Tester’s work queues allow you to assign an account to a technician with a range of dates that they can access it. They cannot access the site outside of that timeframe.                                    </dd>
                            </div>
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={Clock} className={'h-52 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Time-Based Access
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    Assign your techs with time-based app access. They will only be able to use the app within the hours that you set! Your account base is safe with Tech Tester!
                                </dd>
                            </div>
                            <div className={"text-center"}>
                                <dt>
                                    <img alt="" src={Audit} className={'h-52 w-auto mx-auto'}/>
                                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                                        Audit Logs
                                    </p>
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    Detailed audit-logs let you see what your techs were accessing, any data changes being made, and when they were doing it. Transparency for the win!
                                </dd>
                            </div>
                        </dl>
                    </div>


                </div>

                <div className="relative mt-24 py-24 bg-myGray-200">
                    <div className="relative z-10 grid grid-cols-1 lg:grid-cols-2 px-12 justify-center text-center place-items-center">
                        <div>
                            <img
                                className="w-auto mx-auto max-w-2xl px-28 md:px-2"
                                src={HeaderImg}
                                alt="Temp Item"
                            />
                        </div>
                        <div className="mt-6 p-1 md:p-10">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                Want to know how it works?
                            </h2>
                            <p className="mt-4 text-md max-w-5xl">
                                Work queues are used to assign your techs the accounts that they will be servicing and will need access to Central Station data. They let you assign a time period that the tech can access the account information in the Tech Tester application, as well as communicate any service notes or instructions to them.</p><br/>
                            <div className={'flex justify-center'}>
                                <div
                                    className={"relative grid grid-rows-2 justify-center max-w-4xl py-2 space-y-4  items-center"}
                                >
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
                                            Search for a specific account or pick from your recently accessed accounts.
                                        </span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
Choose 'Add to work queue'.                                        </span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
                                            Assign a tech from your tech drop-down list.
                                        </span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
                                            Assign a time period, any notes you may have, and save!
                                        </span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
                                            When a technician opens their Work Queue on the Tech Tester app, the account shows up right in their list if they are in within the time period you assigned.
                                        </span>
                                        </h1>
                                    </div>
                                    <div>
                                        <h1 className="text-left text-md place-items-center tracking-tight flex flex-row space-x-5">
                                    <span className="text-6xl">
                                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                                     </span>
                                            <span className="block">
                                          The technician is presented with the notes that have been added as well as the all of the account information that they need to do their job.
                                        </span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8">
                                <Link
                                    to="/resources/how-it-works"
                                    className="inline-flex bg-gradient-to-r from-silver-light to-silver-light bg-origin-border px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-md text-black hover:from-silver-alt hover:to-silver-alt"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                {/* FAQ Section */}

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6 bg-myGray-300 rounded-md m-5 p-5">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                          />
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}